import { render, staticRenderFns } from "./activityDetails.vue?vue&type=template&id=05a7eb43&scoped=true"
import script from "./activityDetails.vue?vue&type=script&lang=js"
export * from "./activityDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05a7eb43",
  null
  
)

export default component.exports